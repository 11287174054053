import React from 'react';
import { Col, Div, Row, Text } from 'atomize';
import { defaultTextProps, subtitleTextProps } from './styles';

export const SurveyReason = (props) => {
	
	const { data } = props

	const rowProps = { p: {b: '1rem'}}

	return (
		<Row {...rowProps}>
			<Col>
				<Text {...subtitleTextProps}>Scopo</Text>
				<Text {...defaultTextProps}>{data.reason}</Text>
			</Col>
		</Row>
	)
}