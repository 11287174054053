import React,{ useEffect, useState } from 'react';
import { usePollData } from '../../logic/hooks';
import { LoadingLogo } from "raider-ui/animations/LoadingLogo";
import { MultiPageSurvey } from "raider-ui/controls/MultiPageSurvey";
import { ErrorDialog } from "raider-ui/containers/DialogContainer";
import { Div, Anchor, Col} from 'atomize';
import { animated, config, useTransition } from "react-spring";
import { useSurveyGlobalState } from '../../logic/state';
import _ from "lodash";
import { SuccessDialog } from 'raider-ui/containers/DialogContainer/clean/successDialog';
import { submitPoll,isDataValid } from '../../logic/storage';
import { useDatabase } from 'reactfire';
import log from 'loglevel';

const mailto = 'mailto:team@raiderarts.net'

export const SurveyManager = (props) => {
  const surveyData = useSurveyGlobalState()
  const [data, id] = usePollData()
  const [completed, setCompleted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const dbref = useDatabase().ref()

  const validData = isDataValid(data)
  log.log("DATA:", data)
  log.log("VALID DATA:", validData)

  useEffect(() => {
    if (surveyData.getSurveyData(id)?.completed)
      setCompleted(true)
  }, [])

  const onSubmit = async (val) => {
    setSubmitting(true)
    surveyData.setSurveyState(id, { completed: true })
    setSubmitError(!(await submitPoll(dbref, id, val)))
    setCompleted(true)
    setSubmitting(false)
  }

  const transitions = useTransition(data, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 200,
    config: config.slow,
  })

  const submitTransition = useTransition(submitting, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 200,
    config: config.slow,
  })

  const errorDialogProps = {
    title: "Oops...",
    description: <>Il questionario richiesto e' stato chiuso o non esiste! <br/>
      se il problema persiste <Anchor href={mailto} target="_blank">Contattaci!</Anchor>
    </>,
    onClick: () => window.location.reload()
  }

  const submitErrorDialogProps = {
    ...errorDialogProps,
    description: <>Qualcosa e' andato storto nell'invio del feedback, Riprova!<br/>
      se il problema persiste <Anchor href={mailto} target="_blank">Contattaci!</Anchor>
    </>,
  }

  const successProps = {
    title: 'Grazie del tuo Feedback!',
    description: <>Grazie mille di aver speso un po' del tuo prezioso tempo per noi!<br/>
      Se dovessi avere ulteriori suggerimenti, o chiarimenti aggiuntivi, non esitare a contattarci!
    </>,
    onClick: () => window.location.href = mailto
  }

  const centerContentProps = {
    left: ' 50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute'
  }

  const surveyContainerProps = !validData ? centerContentProps : null;

  const multiPageSurveyProps = {
    data: data,
    formValues: surveyData.getSurveyData(id),
    setValues: (val) => surveyData.setSurveyState(id, val),
    onSubmit: onSubmit,
  };

  const content = transitions(({ opacity }, item) =>
    item ?
      (<animated.div style={{
        ...surveyContainerProps,
        opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
      }}>
        {validData && <MultiPageSurvey {...multiPageSurveyProps}/>}
        {!validData && <ErrorDialog {...errorDialogProps}/>}
      </animated.div>) :
      (<animated.div style={{
        ...centerContentProps,
        opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
      }}>
        <LoadingLogo />
      </animated.div>)
  )

  const submitResult = <>
    <div style={{ ...centerContentProps }}>
      {(submitError) && <ErrorDialog {...submitErrorDialogProps} />}
      {(!submitError) && <SuccessDialog {...successProps} />}
    </div>
  </>

  const contentSubmit = submitTransition(({ opacity }, item) => 
    !item ?
    (<animated.div style={{
      opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
    }}>
      {!completed && content}
      {completed && submitResult}
    </animated.div>) :
    (<animated.div style={{
      ...centerContentProps,
      opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
    }}>
      <LoadingLogo />
    </animated.div>)
  )

  
  const contentContainerProps = {
    flexGrow: 1,
    align: 'center',
    p: { t: { xs: '0.5rem', md: '1rem' } },
		maxH: '100%',
		style:{overflowY: 'auto'}
  }

  return (
    <Col {...contentContainerProps}>
      {contentSubmit}
    </Col>
  )
}