import React, { useEffect, useMemo, useState } from 'react';
import log from 'loglevel';
import { useLocation, useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';
import { getActivePoll, getPollResults, isPollActive } from './storage';

/**
 * Parse the url query
 */
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Retrive the id from the params and search it in the database
 */
export function useId() {
  const { id } = useParams()
  log.log("ID useid:",id)
  const [active, setActive] = useState(false)
  const [ready, setReady] = useState(false)
  const dbref = useDatabase().ref()

  useEffect(() => {
    const load = async () => {
      setActive(await isPollActive(dbref, id));
      setReady(true)
    }
    load()
  }, [])

  return [active ? id : null, ready]
}

/**
 * Get the needed data from the database
 */
export function usePollData() {
  const { id } = useParams()
  const query = useQuery()
  const isPreview = useMemo(() => query.get('preview'), [])
  const [data, setData] = useState(null)
  const dbref = useDatabase().ref()

  useEffect(() => {
    const load = async () => {
      const data = await getActivePoll(dbref, id, isPreview)
      log.log("DATA:",data)
      setData(data);
    }
    load()
  }, [])

  return [data, id]
}


export function useResultData() {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const dbref = useDatabase().ref()

  useEffect(() => {
    const load = async () => {
      const data = await getPollResults(dbref, id)
      setData(data)
    }
    load()
  }, [])

  return [data, id]
}