import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/database';
import { FirebaseAppProvider } from 'reactfire';
import firebaseConfig from './firebaseConfig';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import log from 'loglevel';
// log.setDefaultLevel('trace')

ReactDOM.render(
  <BrowserRouter>
  <QueryParamProvider ReactRouterRoute={Route}>
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </FirebaseAppProvider>
  </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
