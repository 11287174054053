import React, { useEffect, useState } from 'react';
import { Dropdown, Anchor, Div, Text, Row, Col } from "atomize";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme, VictoryLabel } from 'victory';
import { Container } from "raider-ui/containers/Container";
import { defaultTextProps } from "./styles";
import log from 'loglevel';
import { useDefaultBreakPoints } from "raider-ui/utils/hooks";
import { mode, median, mean, std } from "mathjs";
import _ from 'lodash';

export const BarResultGraph = (props) => {
	const { questionData, resultData, type } = props
	const [, graphPadding] = useDefaultBreakPoints('width', {sm: {left:150, right: 20, top: 50, bottom: 50}, md: 50})
	
	log.debug("RATE QUESTION DATA:", questionData)
	log.debug("RATE RESULT DATA:", resultData)
	log.debug("RATE RESULT DATA LENGTH:", resultData.length)

	const getCleanGraphData = () => {
		switch (questionData.type) {
			case 'rate':
				return (new Array(7).fill(0)).map((d,i) => ({x:i, y:0}))
			case 'choice':
			case 'multi-choice':
				return questionData.choices.map(d => ({x: d, y: 0}))
		default:
			break;
		}
	}

	const [graphData, setGraphData] = useState(getCleanGraphData())
	
	log.debug("GRAPHDATA:", graphData)

	const sanitizedData = resultData.filter(d => d !== undefined)
	const retriveMultiChoiceData = () => sanitizedData.map(d => {
		if (Array.isArray(d))
			return d
		else if (_.isObjectLike(d))
			return Object.values(d)
	})
	const isDataAvaiable = sanitizedData.length != 0
	log.debug("sanitizedData:", sanitizedData)

	const getTickValues = () => {
		switch (questionData.type) {
			case 'rate':
				return Array(7).fill(0).map((d,i) => i)
			case 'choice':
			case 'multi-choice':
				return questionData.choices
			default:
				break;
		}
	} 
	const nonRisposto = resultData.reduce((a, b) => b == undefined ? a + 1 : a, 0)

	const getChartProps = () => {
		const defaultProps = {
			theme: VictoryTheme.material,
			domainPadding: 20,
			padding: type != 'rate' ? graphPadding : VictoryTheme.material.chart.padding
		}
		
		switch (questionData.type) {
			case 'rate':
				return {
					...defaultProps,
					minDomain: { x: -1 },
				}
			case 'choice':
			case 'multi-choice':
				return {
					...defaultProps,
					horizontal: true
				}
		}
	} 

	const getDependentAxisProps = () => {
		switch (questionData.type) {
			case 'rate':
				return {
					offsetX: 50
				}
			case 'choice':
			case 'multi-choice':
				return { }
		}
	}

	useEffect(() => {
		const tmp = [...getCleanGraphData()]

		switch (questionData.type) {
			case 'rate':
				resultData.forEach(d => {
					if (d != undefined)
						tmp[d].y += 1
				})
				break;
			case 'choice':
				resultData.forEach(d => {
					if (d != undefined)
						tmp[tmp.findIndex((e) => e.x == d)].y += 1
				})
				break;
			case 'multi-choice':
				retriveMultiChoiceData().forEach(d => {
					if (d != undefined) {
						if(Array.isArray(d))
							d.forEach((d1) => tmp[tmp.findIndex((e) => e.x == d1)].y += 1)
						else
							tmp[tmp.findIndex((e) => e.x == d)].y += 1
					}
				})
				break;
		}
		setGraphData(tmp)
	}, [resultData])

	const containerProps = {
		h: {md: 'auto', lg:'400px'},
		style: { position: 'relative' },
		flexGrow: 1,
		justify: {xs: type != 'rate' ? 'flex-end' : null}
	}

	const colProps = {
		maxH: { md: '400px', lg: '100%' },
		minW: { md: '400px', xs: '250px' },
		// maxW: type != 'rate' ? {xs: '200px', md: '100%'} : null
	}

	const leftColProps = {
		border: { r: '1px solid' },
		borderColor: 'gray400' 
	}

	const renderAxis = () =>(
		<VictoryAxis
			tickValues={getTickValues()}
			axisLabelComponent={
				<VictoryLabel
					dy={20}
					style={{ fontWeight: "bold" }}
				/>
			}
			label={type == 'rate' ? `${questionData.gradient.worst} - ${questionData.gradient.good}` : ''}
		/>
	)

	const calcStats = () => {
		switch (questionData.type) {
			case 'rate':
				return [
					["titolo", <Text textWeight="700">{questionData.title}</Text>],
					["tipo", questionData.type],
					["media", isDataAvaiable ? Number(mean(sanitizedData)).toFixed(2) : 'Dati insufficenti'],
					["mediana", isDataAvaiable ? Number(median(sanitizedData)).toFixed(2) : 'Dati insufficenti'],
					["moda", isDataAvaiable ? mode(resultData).toString()  : 'Dati insufficenti'],
					["deviazione standard", isDataAvaiable ? Number(std(sanitizedData)).toFixed(2) : 'Dati insufficenti'],
					["non risposto",`${nonRisposto} / ${ resultData.length } (${((nonRisposto/resultData.length)*100).toFixed(2)}%) `]
				]
			case 'choice':
				return [
					["titolo", <Text textWeight="700">{questionData.title}</Text>],
					["tipo", questionData.type],
					["moda", isDataAvaiable ? mode(resultData).toString() : 'Dati insufficenti'],
					["non risposto",`${nonRisposto} / ${ resultData.length } (${((nonRisposto/resultData.length)*100).toFixed(2)}%) `]
				]
			case 'multi-choice':
				return [
					["titolo", <Text textWeight="700">{questionData.title}</Text>],
					["tipo", questionData.type],
					["moda", isDataAvaiable ? mode(retriveMultiChoiceData()).toString() : 'Dati insufficenti'],
					["non risposto",`${nonRisposto} / ${ resultData.length } (${((nonRisposto/resultData.length)*100).toFixed(2)}%) `]
				]
		}
	}

	const renderStats = (statsList) => {
		const colStatProps = { flexGrow: "0", style:{flexBasis: '200px'} }
		const descProps = { ...defaultTextProps, style: { whiteSpace: 'nowrap' } }
		const rowProps = (index) => ({
      d: 'flex',
      p: '1rem',
      border: { b: statsList.length - 1 != index ? '1px solid' : 0 },
      borderColor: 'gray400',
    });

		return statsList.map((stat, index) => <Row {...rowProps(index)}>
			<Col {...colStatProps}>
				<Text {...descProps}>{stat[0]}:</Text>
			</Col>
			<Col>
				{stat[1]}
			</Col>
		</Row>)
	}

	return (
		<Row {...containerProps}>
			<Col {...leftColProps}>
				{renderStats(calcStats())}
			</Col>
			<Col {...colProps}>
				<VictoryChart {...getChartProps()} >
					<VictoryAxis dependentAxis {...getDependentAxisProps()}/>
					{renderAxis()}
					<VictoryBar style={{ data: { fill: "#c43a31" } }} data={graphData} />
				</VictoryChart>
			</Col>
		</Row>
	)
}