import  log, { getLogger } from 'loglevel';
import Rect, { useEffect } from 'react';
import { SurveyResultSection } from "./resultSection";

export const SurveyResultsWrapper = (props) => {
	const { surveyData, resultData } = props;

	const groups = surveyData?.public.groups
	const resultGroupsData = {}

	groups.forEach(group => {
		const groupResultData = Object.values(resultData).map((item) => {
			return item[group.title]
		}) 
		resultGroupsData[group.title] = groupResultData
	});
	log.debug("DATA PATTERN:", groups)
	log.debug("DATA RESULT FORMATTED:", resultGroupsData)

	return groups.map((group, index) => <SurveyResultSection key={index} section={group} sectionResults={resultGroupsData[group.title]} />)
}
