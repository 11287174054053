import React from 'react';
import { Div, Text, Row } from "atomize";
import { useParams } from 'react-router-dom';
import { titleTextProps } from './styles';


export const SurveyTitleDescription = (props) => {
	const { data } = props
	const {id} = useParams()

	const titleIDTextProps = { textColor:"black500" }

	const titleIDProps = {d: 'flex', align: 'flex-end'}

	return (
	<Row>
		<Text {...titleTextProps}>{data.title}</Text>
		<Div {...titleIDProps}>
			<Text {...titleIDTextProps}>({id})</Text>
		</Div>
	</Row>
	)
}