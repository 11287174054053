import './App.css';
import {Switch,Route} from "react-router-dom";

//RAIDER UI SETUP
import { UIThemeProvider } from "raider-ui/ThemeProvider";
import 'raider-ui/styles/global.css';
import { SurveyManager } from './components/SurveyManager';
import { SurveyResults } from './components/SurveyResults';
import { SurveyResultSelect } from './components/SurveyResultSelect';
import { globalSurveyState, storageKey, useUserGlobalState } from './logic/state';
import firebase from 'firebase';
import { Persistence } from '@hookstate/persistence';
import { Login } from './components/Login';
import { Div } from 'atomize';
import { useEffect } from 'react';
import  log  from 'loglevel';
import { AppHeader } from './components/Header';

function App() {

  const userState = useUserGlobalState()
  
  useEffect(() => {
    globalSurveyState.attach(Persistence(storageKey))
    
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      log.debug("SETTING USER BECAUSE OF STATE CHANGE", user)
      userState.setUser(user)
    })
    return () => unregisterAuthObserver()
  },[])

  const containerProps = {
    d: 'flex',
    flexGrow: 1,
    flexDir: 'column',
    h: '100%',
  }

  return (
    <UIThemeProvider>
      <Div {...containerProps}>
      <AppHeader />
      <Switch>
        <Route exact path="/" children={<SurveyManager />} />
        <Route exact path="/show/logout" children={<Login signOut component={<SurveyResults />} />} />
        <Route exact path="/show" children={<Login component={<SurveyResultSelect />}/>} />
        <Route exact path="/show/:id" children={<Login component={<SurveyResults />}/>} />
        <Route path="/:id" children={<SurveyManager />} />
        </Switch>
      </Div>
    </UIThemeProvider>
  );
}

export default App;
