import { useTransition } from '@react-spring/core';
import React, { useEffect, useState } from 'react';
import { animated } from '@react-spring/web';
import { useDatabase } from 'reactfire';
import { getActivePoll, getCurrentPolls } from '../../logic/storage';
import { Row, Col, Div, Anchor, Text } from 'atomize';
import { config } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

const AnimatedColumn = animated(({ opacity, ...rest }) => {
	return <Col style={{ opacity: opacity }} {...rest}/>
})

const AnimatedRow = animated(({ opacity, count, index, ...rest }) => {
	const rowProps = {
		p: {xs: '0.5rem', md: '1rem'},
		d: 'flex',
		justify: "flex-start",
		border: { b: index != count - 1 ? '1px solid' : "0"},
		borderColor: 'gray700',
		style: { opacity: opacity }
	}

	return <Row {...rowProps} {...rest}/>
})

export const SurveyResultSelect = (props) => {
	const history = useHistory();
	const [polls, setPolls] = useState([])
	const dbref = useDatabase().ref()

	useEffect(() => {
		const getPolls = async () => {
			const currPolls = Object.keys(await getCurrentPolls(dbref))
			const tmp = []
			setPolls([])
			for (const id of currPolls) {
				tmp.push({id: id, pollData: await getActivePoll(dbref, id)})
			}
			setPolls(tmp)
		}
		getPolls()
	}, [])

	const transitions = useTransition(polls, {
		from: { opacity: 0 }, 
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
    config: config.molasses,
	})

	const colProps = {
		m: 0,
		maxH: '100%',
		style: { overflowY: 'auto' },
	}

	const contentProps = {
		d: "flex",
		flexGrow: "1",
		flexDir: 'column',
		p: '1rem',
	}

	const titleProps = {
		tag: 'h1',
		textColor: 'black200',
		textSize: "title",
		p: {b: '1rem'}
	}

	const gridColProps = {
		flexGrow: { md: "0", xs: "1"},
		align: 'center',
		d: 'flex',
		minW: '200px',
		flexWrap: 'nowrap'
	}

	const noDataProps = {
		textAlign: 'center',
		textSize: 'paragraph',
		textColor: 'black100',
	}

	const tableHeaderProps = {
		bg: 'gray400',
		border: { b: '1px solid' },
		borderColor: 'gray700',
	}

	const tableColProps = {
		...gridColProps,
		p: { l: '0.5rem' },
		m: {y: '0.5rem'},
		border: { r: {sm: '1px solid', xs: "0" }},
		borderColor: 'gray700'
	}

	const tableTextProps = {
		textWeight: 'bold',
		textSize: '1rem',
		textColor: 'black400'
	}

	const tableHeader = (
		<AnimatedRow {...tableHeaderProps}>
			<Col {...tableColProps}>
				<Text {...tableTextProps}>ID</Text>
			</Col>
			<Col {...tableColProps} border="0">
				<Text {...tableTextProps}>Titolo</Text>
			</Col>
		</AnimatedRow>
	)

	const redirectAnchorProps = (item) => ({
		onClick: () => history.push(`/show/${item}`)
	})

	return (
		<Div {...contentProps} >
			<Text {...titleProps}>Seleziona un sondaggio per visualizzarne i risultati:</Text>
			{polls.length !== 0 && <>
			<Col {...colProps}>
				{tableHeader}
				{transitions(({ opacity }, item, trans, index) => (
					<AnimatedRow opacity={opacity} index={index} count={polls.length}>
						<Col {...tableColProps}>
							<Anchor {...redirectAnchorProps(item.id)}>{item.id}</Anchor>
						</Col>
						<Col {...tableColProps} border="0">
							<Text>{item.pollData.title}</Text>
						</Col>
					</AnimatedRow>
				))}
			</Col>
			</>}
			{polls.length === 0 && <Text {...noDataProps}>Nessun Sondaggio attivo.</Text>}
		</Div>
	)
}