import React from 'react';
import { BarResultGraph } from './barResult';
import { Col, Text, Div, Icon, Row } from 'atomize';
import { subtitleTextProps } from './styles';
import { useState } from 'react';
import { Collapse } from 'atomize';
import log from 'loglevel';
import { OpenResultList } from './openResult';

export const SurveyResultSection = (props) => {
  const [showCollapse, setShowCollapse] = useState(false);
  const { section, sectionResults } = props;

  log.debug('section', section);
  log.debug('section results', sectionResults);
  const questions = section?.questions;

  const containerProps = (index) => ({
    border: { b: index !== questions.length - 1 && '1px solid' },
    borderColor: 'gray700',
  });

  const rowProps = {
    align: 'center',
    m: { b: '1rem' },
  };

  const colProps = {
    bg: 'gray100',
    border: '1px solid',
    borderColor: 'gray700',
    rounded: 'lg',
    style: { overflow: 'hidden' },
  };

  const renderResultQuestion = (question, index) => {
    const resultData = sectionResults.map((data) => {
      return data?.[index];
    });

    const resultProps = {
      questionData: question,
      resultData: resultData,
    };

    switch (question.type) {
      case 'rate':
        return <BarResultGraph {...resultProps} type="rate" />;
      case 'choice':
        return <BarResultGraph {...resultProps} type="choice" />;
      case 'multi-choice':
        return <BarResultGraph {...resultProps} type="multi-choice" />;
      case 'open':
        return <OpenResultList {...resultProps} />;
      default:
        return null;
    }
  };

  const content = questions?.map((data, index) => (
    <Div key={index} {...containerProps(index)}>
      {renderResultQuestion(data, index)}
    </Div>
  ));

  const iconProps = {
    name: showCollapse ? 'DownArrow' : 'RightArrow',
    size: '20px',
    m: { r: '1rem' },
    onClick: () => setShowCollapse(!showCollapse),
  };

  const collapseProps = {
    m: { b: showCollapse ? { xs: '1.5rem', lg: '2rem' } : 0 },
  };

  return (
    <Col>
      <Row {...rowProps}>
        <Icon {...iconProps} />
        <Text {...subtitleTextProps}>{section?.title}</Text>
      </Row>
      <Collapse isOpen={showCollapse} {...collapseProps}>
        <Div {...colProps}>{content}</Div>
      </Collapse>
    </Col>
  );
};
