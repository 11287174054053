import React from 'react';
import { SimpleHeader } from 'raider-ui/containers/SimpleHeader';
import { ReactComponent as AuroraLogo } from 'raider-ui/assets/Aurora Logo.svg';
import { Text, Div, Anchor } from 'atomize';
import { Button  } from "raider-ui/controls/Button";
import { useUserGlobalState } from '../../logic/state';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import log from 'loglevel';

export const AppHeader = (props) => {

	const history = useHistory();
	const userState = useUserGlobalState()

  const headerProps = {
    bg: 'white',
    shadow: 0,
    border: { b: "1px solid" },
    borderColor: 'gray400'
  }

	const userMenuItemProps = {
		m: {r: '1rem'}
	}

	const listaSondaggiProps = {
		...userMenuItemProps,
		onClick: ()=> history.push('/show/')
	}

	const logOutProps = {
		...userMenuItemProps,
		w: {xs: '100%', md: '100%', lg: 'auto' },
		onClick: async () => { await firebase.auth().signOut()}
	}

  const headerLogoContainerProps = {
    d: "flex",
    flexDir: "column",
    justify: "center",
    flexGrow: 1,
    align: "center",
    maxH: { xs: '25px', md: '40px'}
  } 

	return (
		<SimpleHeader {...headerProps}>
			<SimpleHeader.Center>
				<Div {...headerLogoContainerProps}>
					<AuroraLogo style={{height: '40px'}}/>
					<Text textSize="0.5rem">Feedback</Text>
				</Div>
			</SimpleHeader.Center>
			{userState.isUserLogged() &&
				<SimpleHeader.Right>
					<Anchor {...listaSondaggiProps}>Lista Sondaggi</Anchor>
					<Button {...logOutProps}>Log Out</Button>
				</SimpleHeader.Right>
			}
		</SimpleHeader>
	)
}