import React from "react";
import firebase from 'firebase/app';
import log from "loglevel";

/**
 * Table mapping for Firebase Realtime database
 */
export const dbTable = {
	currentPolls: '/currentPolls/',
	sourcePolls: '/sourcePolls/',
	compiledPolls: '/compiledPolls/'
}

/**
 * Retrive all the active polls
 * @param {firebase.database.Reference} dbref 
 * 
 * @return {Object} result
 */
export async function getCurrentPolls(dbref) {
	try {
		const data = {}
		const ref = dbref.child(dbTable.currentPolls)
		const snapData = await ref.once('value')
		snapData.forEach(childSnap => {
			const childValue = childSnap.val();
			const childKey = childSnap.key;
			data[childKey] = childValue
		})
		return data
	} catch (error) {
		log.error(error)
		return null
	}
}

/**
 * Is the poll active
 * @param {firebase.database.Reference} dbref 
 * @param {number} id
 */
export async function isPollActive(dbref, id) {
	try {
		const ref = dbref.child(dbTable.currentPolls + id)
		const snapdata = await ref.once('value')
		return snapdata.exists()
	} catch (error) {
		log.error(error)
		return null
	}
}


/**
 * Retrive all the active polls
 * @param {firebase.database.Reference} dbref 
 * @param {number} id
 */
export async function getActivePoll(dbref, id, preview=false) {
	try {
		log.log("ID:", id)
		let isActive = await isPollActive(dbref, id);

		if (preview && !isActive) {
			log.warn("Retriving poll that is not active")
			isActive = true
		}

		if (isActive) {
			const ref = dbref.child(dbTable.sourcePolls + id)
			const snapdata = await ref.once('value')
			const data = { ...(snapdata.val()) }
			
			const gradients = data.gradients
			for (const [i,group] of data.public.groups.entries()) {
				for (const [j, question] of group.questions.entries()) {
					log.log(question)
					if (question["gradient"])
						log.log("HAS GRADIENT")
						data.public.groups[i].questions[j].gradient = gradients[question["gradient"]]
				}
			}

			log.log("POLL DATA:", data)
			return data
		} else {
			throw `The poll is not active`
		}
	} catch (error) {
		log.error(error)
		return {}
	}
}

/**
 * Submit a compiled poll
 * @param {firebase.database.Reference} dbref 
 * @param {number} id
 * @param {Object} data 
 * @returns 
 */
export async function submitPoll(dbref, id, data) {
	try {
		const isActive = await isPollActive(dbref, id);
		if (isActive) {
			const ref = dbref.child(dbTable.compiledPolls + id)
			const compiledid = await ref.push()
			await compiledid.set(JSON.parse(JSON.stringify(data)))
			return true
		} else {
			throw `The poll is not active`
		}
	} catch (error) {
		log.error(error)
		return null
	}
}

/**
 * Retrive the result of a poll, must have admin access to do so
 * @param {firebase.database.Reference} dbref 
 * @param {number} id
 * @returns
 */
export async function getPollResults(dbref, id) {
	try {
		const ref = dbref.child(dbTable.compiledPolls + id)
		return (await ref.once('value')).val()
	} catch (error) {
		log.error(error)
		return null
	}
}

/**
 * Check if a paylod of data is valid (contains data)
 * @param {Object} data 
 * @returns 
 */
export function isDataValid(data) {
	return !(data && Object.keys(data).length === 0 && data.constructor === Object)
}