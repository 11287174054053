import React from 'react';
import { createState, useState as useHState } from '@hookstate/core';
import { Persistence } from "@hookstate/persistence";
import firebase from 'firebase/app';
import log from 'loglevel';

export const storageKey = 'ra-feedback'

/**
 * Survey STATE
 */
export const globalSurveyState = createState({})

const surveyState = (s) => ({
	getSurveyData: (id) => s.value[id] ?  {...s.value[id]} : {},
	setSurveyState: (id, data) => s.set(p => {
		p[id] = { ...(p[id]), ...data }
		return p
	}),
	getState: () => s
})

export const accessSurveyGlobalState = () => surveyState(globalSurveyState) //outside component
export const useSurveyGlobalState = () => surveyState(useHState(globalSurveyState)) // inside component


/**
 * USER STATE
 */
export const globalUserState = createState({ user: null, isAllowed: false })

const userState = (s) => ({
	setUser: async (user) => {
		if (user) {
			const token = await firebase.auth().currentUser.getIdTokenResult()
			log.debug("USER FOUND")
			s.set(p => {
				const data = {...p}
				data.user = user
				data.isAllowed = !!token.claims.admin
				return data
			})
		} else {
			log.debug("NO USER")
			s.set({user: null, isAllowed: false})
		}
	},
	isUserLogged: () => !!s.value.user,
	isUserAllowed: () => !!s.value.isAllowed,
	getUser: () => s.value.user
})

export const accessUserGlobalState = () => userState(globalUserState) //outside component
export const useUserGlobalState = () => userState(useHState(globalUserState)) // inside component
