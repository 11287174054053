import React from 'react';
import { Div, Col, Row, Text } from 'atomize';
import { defaultTextProps } from "./styles";


export const OpenResultList = (props) => {
	const { questionData, resultData } = props

	const nonRisposto = resultData.reduce((a, b) => b == undefined ? a + 1 : a, 0)

	const renderStats = (statsList) => {
		const colStatProps = { flexGrow: "0", style: { flexBasis: '200px' } }
		const descProps = { ...defaultTextProps, style: { whiteSpace: 'nowrap' } }
		const rowProps = (index) => ({
			d: 'flex',
			p: '1rem',
			border: { b: statsList.length - 1 != index ? '1px solid' : 0 },
			borderColor: 'gray400',
		});

		return statsList.map((stat, index) => <Row {...rowProps(index)}>
			<Col {...colStatProps}>
				<Text {...descProps}>{stat[0]}:</Text>
			</Col>
			<Col>
				{stat[1]}
			</Col>
		</Row>)
	}

	const stats = [
		["titolo", <Text textWeight="700">{questionData.title}</Text>],
		["non risposto", `${nonRisposto} / ${resultData.length} (${((nonRisposto / resultData.length) * 100).toFixed(2)}%) `]
	]

	const containerProps = {
		h: { md: 'auto', lg: '400px' },
		style: { position: 'relative' },
		flexGrow: 1
	}

	const colProps = {
		d: 'flex',
		flexDir: 'column',
		maxH: { md: '400px', lg: '100%' },
		minW: { md: '400px', xs: '150px' },
		flexGrow: 1,
		style: { overflowY: 'auto' },
	}

	const leftColProps = {
		border: { r: '1px solid' },
		borderColor: 'gray400'
	}

	const itemProps = (index) => ({
		key: index,
		border: { b: index != resultData.length - 1 ? '1px solid' : 0 },
		borderColor: 'gray400',
		p: '0.5rem'
	})

	const contentItems = resultData.map((d, i) => {
		if(d)
			return <Div {...itemProps(i)}>{d}</Div>
		else return null
	})

	return (
		<Row {...containerProps}>
			<Col {...leftColProps}>
				{renderStats(stats)}
			</Col>
			<Col {...colProps}>
				{contentItems}
			</Col>
	</Row>
	)
}