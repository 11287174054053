import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Div, Text } from 'atomize';
import { DialogContainer } from 'raider-ui/containers/DialogContainer';
import { useParams } from 'react-router-dom';
import { SurveyResults } from '../SurveyResults';
import { useTransition } from 'react-spring';
import { animated, config } from '@react-spring/web';
import { ErrorDialog } from 'raider-ui/containers/DialogContainer';
import log from 'loglevel';
import { Redirect } from 'react-router';
import { useUserGlobalState } from '../../logic/state';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.GithubAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export const Login = (props) => {
	const { signOut, component } = props
	const userState = useUserGlobalState()
	log.debug("USER ALLOWED:", userState.isUserAllowed())
	log.debug("USER LOGGED:",userState.isUserLogged())

  useEffect(() => {
		if (signOut) {
			firebase.auth().signOut()
		}
  }, []);

	const transitions = useTransition(userState.isUserLogged(), {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 200,
    config: config.slow,
  })

	const loginDialogProps = {
		title: 'Login',
		headerProps: { bg: 'info600' },
		iconProps: {
			name: "RightArrowSolid",
			color: 'gray200',
		}
	}

	const textProps = {
		textColor: 'black100'
	}
	
  const centerContentProps = {
    d: 'flex',
    flexGrow: 1,
    align: 'center',
    justify: 'center'
	}
	
	const notAllowedErrorProps = {
		title: 'Utente non autorizzato',
		description: "Siamo spiacenti ma non hai i permessi per visualizzare questo contenuto",
		onClick: () => window.location.reload()
	}

	const componentContainerStyle = {
		display: 'flex',
		flexGrow: 1,
		maxHeight: '100%',
		flexDirection: 'column',
	} 

	return transitions(({ opacity }, item) => 
		!item ? (
			<Div { ...centerContentProps }>
				<animated.div style={{opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
					<DialogContainer {...loginDialogProps}>
						<Text {...textProps}>Per piacere esegui il Login:</Text>
						<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
					</DialogContainer>
				</animated.div>
			</Div>
		) :
		( userState.isUserLogged() && userState.isUserAllowed() ? (
				<animated.div style={{ opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }), ...componentContainerStyle }}>
					{component}
				</animated.div>
			) : (
				<Div {...centerContentProps}>
					<animated.div style={{opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] })}}>
						<ErrorDialog {...notAllowedErrorProps} />
					</animated.div>
				</Div>
			)
		)
	)
}