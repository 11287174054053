export const defaultTextProps = {
	textColor: 'black100'
}

export const subtitleTextProps = {
	tag: 'h2',
	textSize: 'subheader',
	textColor: 'black300',
}

export const titleTextProps = {
	tag: 'h1',
	textSize: 'title',
	textColor: 'black600',
	p: { r: '0.5rem'}
}