import React, { useEffect, useState } from 'react';
import { Dropdown, Anchor, Div, Text, Row, Col } from "atomize";
import { getCurrentPolls, getPollResults, isDataValid } from '../../logic/storage';
import log from 'loglevel';
import { useDatabase } from 'reactfire';
import { useParams, withRouter } from 'react-router-dom';
import { usePollData, useResultData } from '../../logic/hooks';
import { animated, useTransition, config } from 'react-spring';
import { LoadingLogo } from 'raider-ui/animations/LoadingLogo';
import { SurveyTitleDescription } from './titleDescription';
import { SurveyReason } from './reason';
import { SurveyResultSection } from './resultSection';
import { SurveyResultsWrapper } from './results';

const AnimatedContent = animated(({ opacity,...rest }) => (
	<Col style={{ opacity: opacity }} {...rest} />
))

export const SurveyResults = (props) => {
	const [pollData, id] = usePollData()
	const [resultData,] = useResultData()
	const validData = isDataValid(pollData)

	log.log("RESULTDATA:",resultData)

	const transition = useTransition(pollData, {
		from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 200,
    config: config.slow,
	})
	
	const centerContentProps = {
		left: ' 50%',
		top: '50%',
		transform: 'translate(-50%,-50%)',
		position: 'absolute'
	}
	
	const contentProps = {
		flexGrow: "1",
		p: '1rem',
		maxH: '100%',
		style:{overflowY: 'auto'}
	}

	const colProps = {

	}

	return transition(({ opacity }, item) => (
		item ?
			(<AnimatedContent {...contentProps} opacity={opacity.to({ range: [0.0, 1.0], output: [0, 1] })}>
				{validData &&
					<Col {...colProps}>
					<SurveyTitleDescription data={pollData} />
					<SurveyReason data={pollData} />
					<SurveyResultsWrapper surveyData={pollData} resultData={resultData} />
					</Col>
				}
			</AnimatedContent>) :
		(<animated.div style={{
			...centerContentProps,
			opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
		}}>
			<LoadingLogo />
		</animated.div>)
	))
}